<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.design-report.title')" />
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openAddItemDialog()">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button color="tertiary">
          <ion-icon :icon="fileTrayFullOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button @click="showReportPreview()" color="primary">
            <ion-icon :icon="eyeOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button @click="openExportConfirmation()" color="success">
            <ion-icon :icon="save"></ion-icon>
          </ion-fab-button>
          <ion-fab-button @click="uploadElement.click()" color="secondary">
            <ion-icon :icon="folderOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button @click="openResetConfirmation()" color="danger">
            <ion-icon :icon="trash"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>

      <a ref="downloadElement" class="invisible-input" target="_blank"></a>
      <input ref="uploadElement" @change="readItemListFile($event.target.files);$event.target.value = null;" type="file" accept=".json,application/json" class="invisible-input"/>
      
      <ion-list ref="mainListRef" class="main-list">
        <ion-reorder-group @ionItemReorder="reorder($event)" :disabled="false">
          <ion-item-sliding v-for="(item, index) in itemList" :key="index" :type="item.type">
            <ion-item>
              <ion-reorder slot="start" :class="(item.type === 'category') ? 'category-reorder' : ((item.type === 'sub_category') ? 'sub_category-reorder' : 'item-reorder')"></ion-reorder>
              <div class="item-text-container" v-if="CATEGORY_TYPES.includes(item.type)">
                <ion-label>
                  <h1>
                    <b>{{ item.name }}</b>
                    <ion-icon v-if="item.pro_only" color="secondary" :icon="medical"></ion-icon>
                  
                    <span v-if="item.repeatable" class="header-small-info">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="reload"></ion-icon>

                      <template v-if="item.integer_index">
                        <span class="text-icon">&nbsp;123</span>
                      </template>

                      <template v-if="item.index_values.length > 0">
                        <span>&nbsp;</span>
                        <span><span class="value-list" v-for="(value, index) in item.index_values" :key="index">{{ value }}</span></span>
                      </template>
                    </span>
                  </h1>
                </ion-label>
              </div>
              <div class="item-text-container" v-else>
                <ion-label position="stacked">
                  {{ i18n.$t('tools.design-report.types.' + item.type) }}
                </ion-label>
                <ion-label>
                  {{ item.name }} {{ (UNIT_TYPES.includes(item.type) && item.unit != null) ? "(" + item.unit + ")" : ""}}
                  <ion-icon v-if="item.pro_only" color="secondary" :icon="medical"></ion-icon>
                  <ion-icon v-if="item.required" color="secondary" :icon="alertOutline"></ion-icon>
                  <ion-icon v-if="item.recommended" color="secondary" :icon="helpOutline"></ion-icon>
                  <ion-icon v-if="(CUSTOM_TYPES.includes(item.type)) && item.allow_custom_values" color="secondary" :icon="text"></ion-icon>

                  <AnimatedLogo v-if="item.analyses != null" name="cycle" stopPattern="offset" class="logo" :enabled="false"></AnimatedLogo>

                  <template v-if="item.custom_placeholder && item.custom_placeholder.length > 0">
                    <span> | </span>
                    <ion-icon color="secondary" :icon="pricetagOutline"></ion-icon>
                    <span> {{ item.custom_placeholder }} </span>
                  </template>

                  <template v-if="item.capture_options">
                    <template v-if="item.capture_options.still_frame">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="imageOutline"></ion-icon>
                    </template>

                    <template v-if="CAMERA_TYPES.includes(item.type) && item.capture_options.capture_type && item.capture_options.capture_type.length > 0">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="scanOutline"></ion-icon>
                      <span> {{ i18n.$t(`camera.hint.${item.capture_options.capture_type}.descriptor`) }} </span>
                    </template>

                    <template v-if="item.capture_options.flash_suggested">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="flashOutline"></ion-icon>
                    </template>

                    <template v-if="item.capture_options.aspect_ratio">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="cropOutline"></ion-icon>
                    </template>
                  </template>

                  <template v-if="(CUSTOM_TYPES.includes(item.type))">
                    <template v-if="item.available_values.length > 0">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="list"></ion-icon>
                      <span><span class="value-list" v-for="(value, index) in item.available_values" :key="index">{{ value }}</span></span>
                    </template>
                    
                    <template v-if="item.allow_multiple_values">
                      <span> | </span>
                      <ion-icon color="secondary" :icon="checkmarkDone"></ion-icon>
                    </template>
                  </template>

                  <template v-if="item.preset_value != null && (item.type === 'bool' || item.preset_value.length === undefined || item.preset_value.length > 0)">
                    <span> | </span>
                    <ion-icon color="secondary" :icon="readerOutline"></ion-icon>
                    <span>
                      {{ (item.type === 'bool') ? ((item.preset_value || item.preset_value === '') ? i18n.$t('default_interaction.yes') : i18n.$t('default_interaction.no')) : item.preset_value }}
                    </span> <!-- Check for edge case, where a text value is set and then the item is changed to bool -->
                  </template>
                </ion-label>
              </div>
            </ion-item>

            <ion-item-options side="end">
              <ion-item-option @click="closeSlidingItems();openCopyConfirmation(index)"><ion-icon slot="icon-only" :icon="copyOutline"></ion-icon></ion-item-option>
              <ion-item-option @click="closeSlidingItems();openDeleteConfirmation(index)" color="danger"><ion-icon slot="icon-only" :icon="trash"></ion-icon></ion-item-option>
              <ion-item-option @click="closeSlidingItems();openEditItemDialog(index)" color="success"><ion-icon slot="icon-only" :icon="pencil"></ion-icon></ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ion-reorder-group>
        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>
      </ion-list>

      <ion-modal
        :is-open="addItemDialogOpen || editItemDialogOpen"
        css-class="modify-item-dialog"
        backdrop-dismiss="false">
        <ion-page>
          <form ref="formInstance" @submit="$event.preventDefault()">
            <ion-header translucent>
              <ion-toolbar>
                <ion-title>
                  {{ editItemDialogOpen ? i18n.$t('tools.design-report.edit_item') : (addItemDialogOpen ? i18n.$t('tools.design-report.add_item') : "") }}
                </ion-title>
                <ion-buttons class="modal-confirmation-buttons" slot="end">
                  <ion-button @click="closeDialogs()" fill="solid" color="danger"><ion-icon slot="icon-only" :icon="close"></ion-icon></ion-button>
                  <ion-button 
                    @click="finishItemModification()" 
                    fill="solid" 
                    color="success"
                    type="submit" >
                    <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-header>

            <ion-content>
              <ion-list lines="full" class="dialog-list">
                <ion-item>
                  <ion-label position="stacked">{{ i18n.$t('tools.design-report.attributes.name') }}</ion-label>
                  <ion-input required :placeholder="i18n.$t('default_interaction.enter_text')" v-model="currentModifiedItem.item.name"></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">{{ i18n.$t('tools.design-report.attributes.type') }}</ion-label>
                  <ion-select ref="categorySelect" @ionChange="checkCategorySelectValidity()" :placeholder="i18n.$t('default_interaction.select')" interface="popover" v-model="currentModifiedItem.item.type">
                    <ion-select-option value="category">{{ i18n.$t('tools.design-report.types.category') }}</ion-select-option>
                    <ion-select-option value="sub_category">{{ i18n.$t('tools.design-report.types.sub_category') }}</ion-select-option>
                    <ion-select-option value="text">{{ i18n.$t('tools.design-report.types.text') }}</ion-select-option>
                    <ion-select-option value="number">{{ i18n.$t('tools.design-report.types.number') }}</ion-select-option>
                    <ion-select-option value="decimal">{{ i18n.$t('tools.design-report.types.decimal') }}</ion-select-option>
                    <ion-select-option value="bool">{{ i18n.$t('tools.design-report.types.bool') }}</ion-select-option>
                    <ion-select-option value="image">{{ i18n.$t('tools.design-report.types.image') }}</ion-select-option>
                    <ion-select-option value="images">{{ i18n.$t('tools.design-report.types.images') }}</ion-select-option>
                    <ion-select-option value="video">{{ i18n.$t('tools.design-report.types.video') }}</ion-select-option>
                    <ion-select-option value="videos">{{ i18n.$t('tools.design-report.types.videos') }}</ion-select-option>
                    <ion-select-option value="date">{{ i18n.$t('tools.design-report.types.date') }}</ion-select-option>
                    <ion-select-option value="datetime">{{ i18n.$t('tools.design-report.types.datetime') }}</ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item>
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.pro_only') }} <ion-icon color="secondary" :icon="medical"></ion-icon></ion-label>
                  <ion-checkbox v-model="currentModifiedItem.item.pro_only"></ion-checkbox>
                </ion-item>
                <!-- An item can never be required and recommended at the same time! -->
                <ion-item v-if="currentModifiedItem.item.type && !CATEGORY_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.required') }} <ion-icon color="secondary" :icon="alertOutline"></ion-icon></ion-label>
                  <ion-checkbox v-model="currentModifiedItem.item.required" :disabled="currentModifiedItem.item.recommended"></ion-checkbox>
                </ion-item>
                <ion-item v-if="currentModifiedItem.item.type && !CATEGORY_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.recommended') }} <ion-icon color="secondary" :icon="helpOutline"></ion-icon></ion-label>
                  <ion-checkbox v-model="currentModifiedItem.item.recommended" :disabled="currentModifiedItem.item.required"></ion-checkbox>
                </ion-item>
                <ion-item v-if="currentModifiedItem.item.type && !CATEGORY_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="stacked">{{ i18n.$t('tools.design-report.attributes.custom_placeholder') }} <ion-icon color="secondary" :icon="pricetagOutline"></ion-icon></ion-label>
                  <ion-input :placeholder="i18n.$t('tools.design-report.custom_placeholder_example') + ' - ' + i18n.$t('default_interaction.optional') " v-model="currentModifiedItem.item.custom_placeholder"></ion-input>
                </ion-item>
                <ion-item v-if="currentModifiedItem.item.type && !CATEGORY_TYPES.includes(currentModifiedItem.item.type) && getAvailableAnalysesForType(currentModifiedItem.item.type).length">
                  <ion-label position="stacked">{{ i18n.$t('tools.design-report.attributes.analyses') }} <AnimatedLogo name="cycle" stopPattern="offset" class="logo" :enabled="false"></AnimatedLogo></ion-label>
                  <ion-select :placeholder="i18n.$t('default_interaction.select')" :compareWith="selectKeyComparisonFunction" v-model="currentModifiedItemAnalyses" multiple> <!-- TODO Check validity? -->
                    <template v-for="(analysisDetails, analysisIndex) in getAvailableAnalysesForType(currentModifiedItem.item.type)" :key="analysisIndex">
                      <ion-select-option :value="analysisDetails">{{ analysisDetails.key }}</ion-select-option>
                    </template>
                  </ion-select>
                </ion-item>
                <ion-item v-if="CAMERA_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.capture_options.still_frame') }} <ion-icon color="secondary" :icon="imageOutline"></ion-icon></ion-label>
                  <ion-checkbox v-model="currentModifiedItem.item.capture_options.still_frame"></ion-checkbox>
                </ion-item>
                <ion-item v-if="CAMERA_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="stacked">{{ i18n.$t('tools.design-report.attributes.capture_options.capture_type') }} <ion-icon color="secondary" :icon="scanOutline"></ion-icon></ion-label>
                  <ion-select :placeholder="i18n.$t('default_interaction.select')" interface="popover" v-model="currentModifiedItem.item.capture_options.capture_type"> <!-- TODO Check validity? -->
                    <ion-select-option v-for="(captureTypeDetails, captureTypeKey) in i18n.$t('camera.hint')" :key="captureTypeKey" :value="captureTypeKey">{{ captureTypeDetails.descriptor }}</ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item v-if="CAMERA_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.capture_options.flash_suggested') }} <ion-icon color="secondary" :icon="flashOutline"></ion-icon></ion-label>
                  <ion-checkbox v-model="currentModifiedItem.item.capture_options.flash_suggested"></ion-checkbox>
                </ion-item>
                <ion-item v-if="CAMERA_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.capture_options.aspect_ratio.square') }} <ion-icon color="secondary" :icon="cropOutline"></ion-icon></ion-label>
                  <ion-checkbox :checked="currentModifiedItem.item.capture_options.aspect_ratio" @ionChange="currentModifiedItem.item.capture_options.aspect_ratio = ($event.target.checked) ? 1 : undefined"></ion-checkbox>
                </ion-item>
                <ion-item v-if="UNIT_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-label position="stacked">{{ i18n.$t('tools.design-report.attributes.unit') }}</ion-label>
                  <ion-input :placeholder="i18n.$t('default_interaction.enter_text') + ' - ' + i18n.$t('default_interaction.optional') " v-model="currentModifiedItem.item.unit"></ion-input>
                </ion-item>

                <template v-if="CUSTOM_TYPES.includes(currentModifiedItem.item.type)">
                  <ion-item>
                    <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.allow_custom_values') }} <ion-icon color="secondary" :icon="text"></ion-icon></ion-label>
                    <ion-checkbox v-model="currentModifiedItem.item.allow_custom_values"></ion-checkbox>
                  </ion-item>
                
                  <ion-list-header>
                    {{ i18n.$t('tools.design-report.attributes.available_values') }}
                    &nbsp;
                    <ion-icon color="secondary" :icon="list"></ion-icon>
                  </ion-list-header>
                  <ion-item>
                    <ion-list class="inner-list">
                      <ion-reorder-group @ionItemReorder="reorderAvailableValues($event, currentModifiedItem)" :disabled="false">
                        <ion-item v-for="(value, index) in currentModifiedItem.item.available_values" :key="index">
                          <ion-reorder slot="start"></ion-reorder>
                          <ion-input required 
                            :type="(NUMBER_TYPES.includes(currentModifiedItem.item.type)) ? 'number' : 'text'"
                            :inputmode="(currentModifiedItem.item.type === 'decimal' ? 'decimal' : undefined)" 
                            :step="(currentModifiedItem.item.type === 'decimal' ? '.001' : undefined)"
                            :placeholder="i18n.$t('tools.design-report.enter_available_value')" v-model="currentModifiedItem.item.available_values[index]">
                          </ion-input>
                          <ion-buttons class="item-action-buttons" slot="end">
                            <ion-button @click="currentModifiedItem.item.available_values.splice(index, 1)" fill="solid" color="danger"><ion-icon slot="icon-only" :icon="trash"></ion-icon></ion-button>
                          </ion-buttons>
                        </ion-item>
                      </ion-reorder-group>
                      <ion-item button @click="currentModifiedItem.item.available_values.push(null)" detail="false" color="success">
                        <ion-label position="fixed"><b>{{ i18n.$t('tools.design-report.add_custom_value') }}</b></ion-label>
                        <ion-icon slot="end" :icon="add"></ion-icon>
                      </ion-item>
                    </ion-list>
                  </ion-item>
                </template>

                <ion-item v-if="currentModifiedItem.item.type === 'text'">
                  <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.allow_multiple_values') }} <ion-icon color="secondary" :icon="checkmarkDone"></ion-icon></ion-label>
                  <ion-checkbox v-model="currentModifiedItem.item.allow_multiple_values" :disabled="currentModifiedItem.item.available_values.length < 2"></ion-checkbox> <!-- Only enable this option,when there are actually more than one value selectable -->
                </ion-item>

                <ion-item v-if="PRESET_TYPES.includes(currentModifiedItem.item.type)" class="item-with-autocomplete">
                  <ion-label :position="(currentModifiedItem.item.type === 'bool') ? 'fixed' : 'stacked'">{{ i18n.$t('tools.design-report.attributes.preset_value') }} <ion-icon color="secondary" :icon="readerOutline"></ion-icon></ion-label>
                  <ion-input v-if="CUSTOM_TYPES.includes(currentModifiedItem.item.type)"
                    :type="(NUMBER_TYPES.includes(currentModifiedItem.item.type)) ? 'number' : 'text'"
                    :inputmode="(currentModifiedItem.item.type === 'decimal' ? 'decimal' : undefined)" 
                    :step="(currentModifiedItem.item.type === 'decimal' ? '.001' : undefined)"
                    :placeholder="i18n.$t('tools.design-report.enter_available_value') + ' - ' + i18n.$t('default_interaction.optional')"
                    v-model="currentModifiedItem.item.preset_value">
                  </ion-input>
                  <ion-checkbox v-else-if="currentModifiedItem.item.type === 'bool'" v-model="currentModifiedItem.item.preset_value" :indeterminate='currentModifiedItem.item.preset_value == null'></ion-checkbox> <!-- TODO Add option to clear value for indeterminate option -->
                  <div class="autocomplete" v-if="filterAutocompleteArray(currentModifiedItem.item.available_values, currentModifiedItem.item.preset_value).length > 0">
                    <div class="list">
                      <ion-list>
                        <!-- Show a filtered list of values that contain the already input string as a autocomplete list -->
                        <ion-item v-for="(value, index) in filterAutocompleteArray(currentModifiedItem.item.available_values, currentModifiedItem.item.preset_value)"
                          :key="index" 
                          button 
                          detail="false" 
                          @click="currentModifiedItem.item.preset_value = value">{{ value }}</ion-item>
                      </ion-list>
                    </div>
                  </div> <!-- FIXME Autocomplete funktioniert noch nicht, da es ausgeblendet wird, sobald man irgendwo klickt und man kann auch nicht scrollen. Sollte außerhalb des items sein. Am Besten -->
                </ion-item>

                <template v-if="currentModifiedItem.item.type === 'sub_category'">
                  <ion-item>
                    <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.repeatable') }} <ion-icon color="secondary" :icon="reload"></ion-icon></ion-label>
                    <ion-checkbox v-model="currentModifiedItem.item.repeatable"></ion-checkbox>
                  </ion-item>

                  <ion-item class="inset-sub-item">
                    <ion-label position="fixed">{{ i18n.$t('tools.design-report.attributes.integer_index') }} <span class="text-icon">123</span></ion-label>
                    <ion-checkbox v-model="currentModifiedItem.item.integer_index" :disabled="!(currentModifiedItem.item.repeatable) || (Array.isArray(currentModifiedItem.item.index_values) && currentModifiedItem.item.index_values.length > 0)"></ion-checkbox> <!-- Only enable this option, when repeatable is enabled and no indizes are specified -->
                  </ion-item>

                  <ion-list-header class="inset-sub-item" :disabled="!(currentModifiedItem.item.repeatable) || currentModifiedItem.item.integer_index">
                    {{ i18n.$t('tools.design-report.attributes.index_values') }}
                  </ion-list-header>
                  <ion-item class="inset-sub-item">
                    <ion-list class="inner-list">
                      <ion-reorder-group @ionItemReorder="reorderIndexValues($event, currentModifiedItem)" :disabled="false">
                        <ion-item v-for="(value, index) in currentModifiedItem.item.index_values" :key="index">
                          <ion-reorder slot="start"></ion-reorder>
                          <ion-input required 
                            type="text"
                            :placeholder="i18n.$t('tools.design-report.enter_index_value')"
                            v-model="currentModifiedItem.item.index_values[index]">
                          </ion-input>
                          <ion-buttons class="item-action-buttons" slot="end">
                            <ion-button @click="currentModifiedItem.item.index_values.splice(index, 1)" fill="solid" color="danger"><ion-icon slot="icon-only" :icon="trash"></ion-icon></ion-button>
                          </ion-buttons>
                        </ion-item>
                      </ion-reorder-group>
                      <ion-item button @click="currentModifiedItem.item.index_values.push(null)" detail="false" color="success" :disabled="!(currentModifiedItem.item.repeatable) || currentModifiedItem.item.integer_index"> <!-- Only enable this option, when repeatable is enabled and integer index is not selected -->
                        <ion-label position="fixed"><b>{{ i18n.$t('tools.design-report.add_index_value') }}</b></ion-label>
                        <ion-icon slot="end" :icon="add"></ion-icon>
                      </ion-item>
                    </ion-list>
                  </ion-item>
                </template>
              </ion-list>
            </ion-content>
          </form>
        </ion-page>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonIcon,
  IonReorderGroup,
  IonItem,
  IonReorder,
  IonLabel,
  IonModal,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonList,
  IonListHeader,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  alertController,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  modalController
} from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';

import AnimatedLogo from '@/components/AnimatedLogo.vue';

import CreateReport from '@/views/Analysis/CreateReport.vue';

import { ref, computed } from 'vue';

import { add, save, close, checkmark, trash, pencil, medical, text, list, copyOutline, fileTrayFullOutline, folderOutline, alertOutline, pricetagOutline, readerOutline, checkmarkDone, imageOutline, scanOutline, flashOutline, cropOutline, reload, helpOutline, eyeOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { localError } from "@/utils/error";

import { createIonSelectComparisonFunction } from "@/utils/interaction"

import _ from 'lodash';

export default  {
  name: 'DesignReport',
  components: {
    IonHeader,
    IonContent,
    IonPage,
    MainToolbar,
    IonFab,
    IonFabList,
    IonFabButton,
    IonIcon,
    IonReorderGroup,
    IonItem,
    IonReorder,
    IonLabel,
    IonModal,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonList,
    IonListHeader,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    AnimatedLogo
  },
  setup() {
    const i18n = useI18n();

    const mainListRef = ref(null);

    const itemList = ref([]);

    const formInstance = ref(null);

    const categorySelect = ref(null);

    const addItemDialogOpen = ref(false);

    const editItemDialogOpen = ref(false);

    const currentModifiedItem = ref({'index': null, 'item': null});

    const currentModifiedItemAnalyses = computed({
      get: () => {
        if (currentModifiedItem.value != null && currentModifiedItem.value.item != null && currentModifiedItem.value.item.analyses != null) {
          return _.map(currentModifiedItem.value.item.analyses, (mapping, key) => {
            return {
              key,
              mapping
            };
          });
        }
        return undefined;
      },
      set: (newValues) => {
        let mappedAnalyses = {};
        for (let value of newValues) {
          if (value != null && value.key != null && value.mapping != null) mappedAnalyses[value.key] = value.mapping;
        }

        //If no new analyses selected set to undefined
        if (mappedAnalyses == null || Object.keys(mappedAnalyses).length <= 0) mappedAnalyses = undefined;

        _.updateWith(currentModifiedItem.value, 'item.analyses', (value) => {
          //Only update on change to not run into loop
          if (value == null || !(_.isEqual(value, mappedAnalyses))) return mappedAnalyses;
          return value
        }, Object);
      }
    });

    const selectKeyComparisonFunction = createIonSelectComparisonFunction('key');

    const downloadElement = ref(null);
    const uploadElement = ref(null);

    const reportName = ref(null);

    const NUMBER_TYPES = ['number', 'decimal'];

    const UNIT_TYPES = [...NUMBER_TYPES, 'text'];

    const CUSTOM_TYPES = ['text', 'number', 'decimal'];

    //Types of categories that have child elements below them - Sorted by their tiers from high to low
    //Sorted property used to determine how many elements to move. Select items to move until the same or a higher tier of category is reached
    const CATEGORY_TYPES = ['category', 'sub_category'];

    const UPLOAD_TYPES = ['image', 'images', 'video', 'videos'];

    const CAMERA_TYPES = ['image', 'images', 'video', 'videos'];

    const PRESET_TYPES = [...CUSTOM_TYPES, 'bool'];

    const reorder = function(event){
      //Finishes the changes in the DOM
            
      let oldIndex = event.detail.from;
      let newIndex = event.detail.to;
      let item = itemList.value[oldIndex];
      //If it is a category/sub_category move every child element (every item until next current category/sub_category or end)
      //We check if we hit the same type again. If it is a category, move the whole category, including all subcategories, if it is a sub_category, only move this sub_category
      if (CATEGORY_TYPES.includes(item.type)){
        //Search for the index of the next category/sub_category
        let nextCategoryIndex = oldIndex + 1;

        //While a next element exists and it is no new category/sub_category, we are still in the current category/sub_category
        while (nextCategoryIndex < itemList.value.length && !(CATEGORY_TYPES.includes(itemList.value[nextCategoryIndex].type) 
        && CATEGORY_TYPES.indexOf(itemList.value[nextCategoryIndex].type) <= CATEGORY_TYPES.indexOf(item.type))){ //Select items to move until the same or a higher tier of category is reached
          //Check, if it moved enough to be outside of the current category/sub_category, if we hit 'to' before the next category/sub_category or the end, it did not move enough
          if (nextCategoryIndex === event.detail.to){
            event.detail.complete(false);
            return false;
          }
          nextCategoryIndex++;
        }

        //If we land exactly at the place of an existing category/sub_category when moving forward, we have to move one step to check correctly in the next while loop for the end of the category/sub_category
        //If moving the item backwards in the list, it does not have to be checked, because it moves the target category/sub_category forward
        if (newIndex > oldIndex && newIndex < itemList.value.length && CATEGORY_TYPES.includes(item.type)){
          newIndex++;
        }

        //Check where to move (after the category/sub_category where it was dropped)
        while (newIndex < itemList.value.length && !(CATEGORY_TYPES.includes(itemList.value[newIndex].type) 
        && CATEGORY_TYPES.indexOf(itemList.value[newIndex].type) <= CATEGORY_TYPES.indexOf(item.type))){ //Again search for same or higher tier. It is placed at the position of the start of this tier
          newIndex++;
        }

        //Check if the position changed at all or if the new position equals the old one
        if (newIndex === oldIndex){
          event.detail.complete(false);
          return false;
        }

        //Split the array at the target element to make a 'sandwich' for the new element group to sit in
        let frontHalf = itemList.value.slice(0, newIndex);
        let backHalf = itemList.value.slice(newIndex);

        //Extract the group to be moved from its respective half
        let offset = oldIndex - newIndex; //Where is the oldIndex relative to the executed split
        let groupLength = nextCategoryIndex - oldIndex;
        let itemsToBeMoved;
        if (offset < 0){
          itemsToBeMoved = frontHalf.splice(offset, groupLength);
        } else {
          itemsToBeMoved = backHalf.splice(offset, groupLength);
        }

        //Call false on complete, because we reorder ourselves
        event.detail.complete(false);

        //'Sandwich' all three parts together
        itemList.value = frontHalf.concat(itemsToBeMoved, backHalf);
      } else {
        itemList.value = event.detail.complete(itemList.value);
      }

      return true;
    }

    const addItem = function(item){
      itemList.value.push(item);
    }

    const insertItem = function(index, item){
      itemList.value.splice(index, 0, item);
    }

    const replaceItem = function(index, item){
      itemList.value[index] = item;
    }

    const removeItem = function(index){
      itemList.value.splice(index, 1);
    }

    const closeSlidingItems = function(){
      mainListRef.value.$el.closeSlidingItems();
    }

    const openCopyConfirmation = async function(index){
      let oldItem = itemList.value[index];
      let newItem = copyItem(oldItem);

      const alert = await alertController
        .create({
          cssClass: 'delete-confirmation-alert',
          header: i18n.$t('tools.design-report.copy-confirmation.title'),
          message: i18n.$t('tools.design-report.copy-confirmation.message'),
          inputs: [ {
              name: 'newNameInput',
              id: 'copy-new-name-input',
              value: newItem.name,
              placeholder: i18n.$t('tools.design-report.copy-confirmation.placeholder')
            }
          ],
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.design-report.copy-confirmation.copy'),
              cssClass: 'copy-confirmation-okay',
              handler: (alertData) => {
                if (alertData.newNameInput.length > 0 && alertData.newNameInput !== oldItem.name){
                  newItem.name = alertData.newNameInput;

                  //Insert at the next position after the current one by default
                  let nextIndex = index + 1;
                  //If it is a category/sub_category, insert after the last element of the current category/sub_category
                  if (CATEGORY_TYPES.includes(newItem.type)){
                    //While a next element exists and it is no new category/sub_category, we are still in the current category/sub_category
                    while (nextIndex < itemList.value.length && !(CATEGORY_TYPES.includes(itemList.value[nextIndex].type) 
                    && CATEGORY_TYPES.indexOf(itemList.value[nextIndex].type) <= CATEGORY_TYPES.indexOf(newItem.type))){ //Select items to copy until the same or a higher tier of category is reached
                      nextIndex++;
                    }
                    //Copy every child element in reverse order to preserve the nextIndex
                    for (let currentChildIndex = nextIndex - 1; currentChildIndex > index; currentChildIndex--){
                      insertItem(nextIndex, copyItem(itemList.value[currentChildIndex]));
                    }
                  }

                  insertItem(nextIndex, newItem);
                  return true;
                } else {
                  document.getElementById('copy-new-name-input').classList.remove('flash-input-short');
                  document.getElementById('copy-new-name-input').offsetHeight; //Reflow to restart animation
                  document.getElementById('copy-new-name-input').classList.add('flash-input-short');

                  return false;
                }
              },
            },
          ],
        });
      return alert.present();
    }

    const openDeleteConfirmation = async function(index){
        const alert = await alertController
        .create({
          cssClass: 'delete-confirmation-alert',
          header: i18n.$t('tools.design-report.delete-confirmation.title'),
          message: i18n.$t('tools.design-report.delete-confirmation.message.before') + '<b>' + itemList.value[index].name + '</b>' + i18n.$t('tools.design-report.delete-confirmation.message.after'),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.design-report.delete-confirmation.delete'),
              cssClass: 'delete-confirmation-okay',
              handler: () => {
                removeItem(index);
              },
            },
          ],
        });
      return alert.present();
    }

    //Creates a deep copy of a given item
    //If no item is given or any of the attributes are empty, The default value is used. This also ensures compatibility if old files that have different attributes
    const copyItem = function(item){
      if (typeof item === 'undefined') item = {}; //Create empty object if no item is provided to catch mistakes
      let newItem = {
        'name': ('name' in item) ? item.name : null,
        'type': ('type' in item) ? item.type : null,
        'unit': ('unit' in item) ? item.unit : null,
        'pro_only': ('pro_only' in item) ? item.pro_only : false,
        'required': ('required' in item) ? item.required : false,
        'recommended': ('recommended' in item) ? item.recommended : false,
        'custom_placeholder': ('custom_placeholder' in item) ? item.custom_placeholder : null,
        'analyses': ('analyses' in item) ? _.cloneDeep(item.analyses) : null,
        'preset_value': ('preset_value' in item) ? item.preset_value : null,
        'available_values': ('available_values' in item && item.available_values != null && 'length' in item.available_values) ? Array(item.available_values.length) : [],
        'allow_custom_values': ('allow_custom_values' in item) ? item.allow_custom_values : true,
        'allow_multiple_values': ('allow_multiple_values' in item) ? item.allow_multiple_values : false,
        'capture_options': {
          'still_frame': ('capture_options' in item && 'still_frame' in item.capture_options) ? item.capture_options.still_frame : undefined,
          'capture_type': ('capture_options' in item && 'capture_type' in item.capture_options) ? item.capture_options.capture_type : 'default',
          'flash_suggested': ('capture_options' in item && 'flash_suggested' in item.capture_options) ? item.capture_options.flash_suggested : undefined,
          'aspect_ratio': ('capture_options' in item && 'aspect_ratio' in item.capture_options) ? item.capture_options.aspect_ratio : undefined
        },
        'repeatable': ('repeatable' in item) ? item.repeatable : false,
        'integer_index': ('integer_index' in item) ? item.integer_index : false,
        'index_values': ('index_values' in item && item.index_values != null && 'length' in item.index_values) ? Array(item.index_values.length) : [],
      };
      //Copy arrays
      for (let arrayProperty of ['available_values', 'index_values']) {
        for (let i = 0; (arrayProperty in item && item[arrayProperty] != null && 'length' in item[arrayProperty]) && i < item[arrayProperty].length; i++){
          newItem[arrayProperty][i] = item[arrayProperty][i];
        }
      }
      return newItem;
    }

    const openAddItemDialog = function(){
      currentModifiedItem.value.index = null;
      currentModifiedItem.value.item = copyItem();
      addItemDialogOpen.value = true;
    }

    const openEditItemDialog = function(itemIndex){
      let item = itemList.value[itemIndex];
      currentModifiedItem.value.index = itemIndex;
      //Make a copy to modify
      currentModifiedItem.value.item = copyItem(item);

      editItemDialogOpen.value = true;
    }

    const availableAnalyses = computed(() => {
      return [
        {key: 'AEye', mapping: 'data', allowedTypes: ['image', 'images']}
      ]
      //TODO Get from model with version_information
    });

    const getAvailableAnalysesForType = computed(() => {
      return function(type){
        return _.filter(availableAnalyses.value, (analysis) => {
          return (analysis.allowedTypes == null || analysis.allowedTypes.includes(type));
        });
      }
    })

    const checkCategorySelectValidity = function(){
      if (categorySelect.value.$el.value.length > 0){
        categorySelect.value.$el.classList.remove('flash-input');
        return true;
      } else {
        categorySelect.value.$el.classList.add('flash-input');
        return false;
      }
    }

    const finishItemModification = function(){
      if (formInstance.value.checkValidity() && checkCategorySelectValidity()){
        if(addItemDialogOpen.value){ 
          addItem(currentModifiedItem.value.item);
        } else if(editItemDialogOpen.value){
          replaceItem(currentModifiedItem.value.index, currentModifiedItem.value.item);
        }
        closeDialogs();
      }
    }

    const closeDialogs = function(){
      addItemDialogOpen.value = false;
      editItemDialogOpen.value = false;
      categorySelect.value.$el.classList.remove('flash-input');
    }

    const reorderAvailableValues = function(event, item){
      //Reorders the list and finishes the changes in the DOM
      item.item.available_values = event.detail.complete(item.item.available_values);
    }

    const reorderIndexValues = function(event, item){
      //Reorders the list and finishes the changes in the DOM
      item.item.index_values = event.detail.complete(item.item.index_values);
    }

    const openExportConfirmation = async function(){
      const alert = await alertController
        .create({
          cssClass: 'export-confirmation-alert',
          header: i18n.$t('tools.design-report.export-confirmation.title'),
          message: i18n.$t('tools.design-report.export-confirmation.message'),
          inputs: [ {
              name: 'filenameInput',
              id: 'export-filename-input',
              value: reportName.value,
              placeholder: i18n.$t('tools.design-report.export-confirmation.placeholder')
            }
          ],
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.design-report.export-confirmation.export'),
              cssClass: 'export-confirmation-okay',
              handler: (alertData) => {
                if (alertData.filenameInput.length > 0){
                  reportName.value = alertData.filenameInput;
                  exportItemList(alertData.filenameInput);

                  return true;
                } else {
                  document.getElementById('export-filename-input').classList.remove('flash-input-short'); //TODO Funktioniert das überhaupt, weil es die Klasse unten für diese ID nicht gibt?
                  document.getElementById('export-filename-input').offsetHeight; //Reflow to restart animation
                  document.getElementById('export-filename-input').classList.add('flash-input-short');

                  return false;
                }
              },
            },
          ],
        });
      return alert.present();
    }


    /*Creates a object structure with the categories as properties
    **Every item is either in its category or uncategorized in the array 'items'
    **Unneeded or not entered optional attributes are ommitted in the export
    **Export is done to json via a URI encoded anchor element href attribute
    */
    const exportItemList = function(filename, download = true){
      let exportArray = [{'name': 'uncategorized', 'items': []}];
      let currentModifiedCategory = exportArray[0]; //Only hold the category to add new sub_categories to.
      let currentModifiedObject = currentModifiedCategory; //Use pass by reference, to modify the object at this position. Corresponds to the object of the current category/sub_category

      for (let item of itemList.value) {
        if (item.type === 'category'){
          let newArrayLength = exportArray.push({'name': item.name, 'pro_only': item.pro_only, 'items': []});
          currentModifiedObject = exportArray[newArrayLength - 1];
          currentModifiedCategory = currentModifiedObject;
        } else if (item.type === 'sub_category'){ //This, the template above and the load are the only parts where the sub_category is explicitly checked for. Everywhere else the tier system by the sorted array CATEGORY_TYPES is used.
          //Add a sub_category array in the current modified object if it does not exist yet
          if (!('sub_categories' in currentModifiedCategory)) {
            currentModifiedCategory['sub_categories'] = [];
          }
          let newArrayLength = currentModifiedCategory['sub_categories'].push({
            'name': item.name,
            'pro_only': item.pro_only,
            'repeatable': item.repeatable,
            //Only allow integer or index_values not both! And only if repeatable is enabled
            'integer_index': (!(item.repeatable) || (Array.isArray(item.index_values) && item.index_values.length > 0)) ? undefined : item.integer_index,
            'index_values': (!(item.repeatable) || item.integer_index) ? undefined : item.index_values,
            'items': []
          });
          currentModifiedObject = currentModifiedCategory['sub_categories'][newArrayLength - 1];
        } else {
          let newItem = {'name': item.name, 'type': item.type, 'pro_only': item.pro_only, 'required': item.required, 'recommended': (item.required) ? undefined : item.recommended }; //Recommended can only be set, if not already required!
          
          if (CUSTOM_TYPES.includes(item.type)){
            newItem['allow_custom_values'] = item.allow_custom_values;
          }


          if (item.custom_placeholder != null && item.custom_placeholder.length > 0) {
            newItem['custom_placeholder'] = item.custom_placeholder;
          }

          if (item.analyses != null && Object.keys(item.analyses).length > 0) {
            newItem['analyses'] = _.cloneDeep(item.analyses);
          }

          if (item.preset_value != null){
            if (item.type === 'bool') {
              newItem['preset_value'] = (item.preset_value || item.preset_value === '') ? true : false; //Check for edge case, where a text value is set and then the item is changed to bool
            } else if (item.type === 'number') {
              newItem['preset_value'] = parseInt(item.preset_value, 10); //Return a number instead of string
            } else if (item.type === 'decimal') {
              newItem['preset_value'] = parseFloat(item.preset_value); //Return a decimal number instead of string
            } else if (item.preset_value.length === undefined || item.preset_value.length > 0) { //Either it is not a string and length is not defined, then use the value, else check the length of the string
              newItem['preset_value'] = item.preset_value;
            }
          }

          if (UNIT_TYPES.includes(item.type)){
            if (item.unit !== null && item.unit.length > 0){
              newItem['unit'] = item.unit;
            }
          }

          if (CAMERA_TYPES.includes(item.type)){
            let new_capture_options = {};
            if (item.capture_options != null) {
              if (item.capture_options['still_frame'])
                new_capture_options['still_frame'] = item.capture_options['still_frame'];
              if (item.capture_options['capture_type']) {
                if (item.capture_options['capture_type'] !== 'default')
                  new_capture_options['capture_type'] = item.capture_options['capture_type'];
              }
              if (item.capture_options['flash_suggested'])
                new_capture_options['flash_suggested'] = item.capture_options['flash_suggested'];
              if (item.capture_options['aspect_ratio'])
                new_capture_options['aspect_ratio'] = item.capture_options['aspect_ratio'];
            }

            //Only add if we have at least one valid option
            if (Object.keys(new_capture_options).length > 0)
              newItem['capture_options'] = new_capture_options;
          }
           
          if (item.available_values.length > 0){
            if (item.type === 'text') {
              newItem['allow_multiple_values'] = item.allow_multiple_values; //Only allow on text, because they are saved as a comma separated list of values
              newItem['available_values'] = item.available_values;
            } else if (item.type === 'number') {
              newItem['available_values'] = item.available_values.map(value => parseInt(value, 10)); //Return an array with numbers instead of strings
            } else if (item.type === 'decimal') {
              newItem['available_values'] = item.available_values.map(value => parseFloat(value)); //Return an array with decimal numbers instead of strings
            }
          }
          
          currentModifiedObject.items.push(newItem);
        }
      }

      if (download === true) {
        let exportString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportArray, null, 2));

        downloadElement.value.setAttribute('href', exportString);
        downloadElement.value.setAttribute('download', filename + '.json');
        downloadElement.value.click();
      }

      return exportArray;
    }

    const loadItemList = function(jsonObject){
      let newItemList = [];

      //New format using arrays to preserve order
      if (Array.isArray(jsonObject)) {
        for (let category of jsonObject){
          //Only add a category and the items to the end, if it is not uncategorized
          if (category.name !== 'uncategorized'){
            let categoryItem = { 'name': category.name, 'type': 'category', 'pro_only': category.pro_only };
            newItemList.push(copyItem(categoryItem));
            for (let item of category.items){
              newItemList.push(copyItem(item));
            }
          } 
          //If uncategorized, just add the items at the beginning
          else {
            for (let item of category.items.reverse()){ //Reverse the order because unshift will reverse it intrinsically by adding it all at the beginning
              newItemList.unshift(copyItem(item)); 
            }
          }

          //Load sub_categories
          if ('sub_categories' in category) { //This, the template above and the export are the only parts where the sub_category is explicitly checked for. Everywhere else the tier system by the sorted array CATEGORY_TYPES is used.
            for (let subCategory of category.sub_categories){
              let subCategoryItem = {
                'name': subCategory.name,
                'type': 'sub_category',
                'pro_only': subCategory.pro_only,
                'repeatable': subCategory.repeatable,
                //Only allow integer or index_values not both! And only if repeatable is enabled
                'integer_index': (!(subCategory.repeatable) || (Array.isArray(subCategory.index_values) && subCategory.index_values.length > 0)) ? undefined : subCategory.integer_index,
                'index_values': (!(subCategory.repeatable) || subCategory.integer_index) ? undefined : subCategory.index_values
              };
              newItemList.push(copyItem(subCategoryItem));
              for (let item of subCategory.items){
                newItemList.push(copyItem(item));
              }
            }
          }
        }
      } 
      //Old format using objects
      else {
        
        for (let item of jsonObject.uncategorized.items){
          newItemList.push(copyItem(item));
        }

        //Delete uncategorized from this object for further processing
        delete jsonObject.uncategorized;

        for (let [name, category] of Object.entries(jsonObject)){
          let categoryItem = { 'name': name, 'type': 'category', 'pro_only': category.pro_only };
          newItemList.push(copyItem(categoryItem));
          for (let item of category.items){
            newItemList.push(copyItem(item));
          }
        }
      }
      
      itemList.value = newItemList;
    }

    const readItemListFile = function(files){
      if (files.length === 1){
        try {
          let jsonFile = files[0];
          //Remove file extension
          reportName.value = jsonFile.name.replace(/\.[^/.]+$/, '');

          let reader = new FileReader(); 
          reader.onload = function(){ 
            try {
              loadItemList(JSON.parse(reader.result));
            } catch (error) {
              localError(i18n, i18n.$t('tools.design-report.load_error'), error.message);
            }
          } 
                  
          reader.readAsText(jsonFile);
        } catch (error) {
          localError(i18n, i18n.$t('tools.design-report.load_error'), error.message);
        }
      }
    }

    const openResetConfirmation = async function(){
        const alert = await alertController
        .create({
          cssClass: 'reset-confirmation-alert',
          header: i18n.$t('tools.design-report.reset-confirmation.title'),
          message: i18n.$t('tools.design-report.reset-confirmation.message'),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.design-report.reset-confirmation.reset'),
              cssClass: 'reset-confirmation-okay',
              handler: () => {
                resetItemList();
              },
            },
          ],
        });
      return alert.present();
    }

    const resetItemList = function(){
      itemList.value = [];
      reportName.value = null;
    }

    const filterAutocompleteArray = function(array, input){
      //Use empty string if input is null
      let compareString = (input) ? input : '';
      //Show only options that contain the already input string and do not show strings that are equal, because they are already input!
      return array.filter((value) => 
        (value && value !== compareString && value.includes(compareString))
      )
    }

    const showReportPreview = async function() {
      let reportPreviewSlug = 'internal-preview-report-design';
      let reportTypeTitle = reportName.value || i18n.$t('tools.design-report.preview') || 'PREVIEW';

      const modal = await modalController
      .create({
        cssClass: 'report-preview-dialog',
        backdropDismiss: true,
        component: CreateReport,
        componentProps: {
          types: reportPreviewSlug,
          previewModalMode: true,
          preLoadedTypes: {
            [reportPreviewSlug]: {
              descriptor: reportTypeTitle,
              definition: exportItemList(reportTypeTitle, false)
            }
          }
        }
      });
      modal.present();
    }
    

    return { 
      i18n,
      add,
      save,
      close,
      checkmark,
      trash,
      pencil,
      medical,
      text,
      list,
      copyOutline,
      fileTrayFullOutline,
      folderOutline,
      alertOutline,
      pricetagOutline,
      readerOutline,
      checkmarkDone,
      imageOutline,
      scanOutline,
      flashOutline,
      cropOutline,
      reload,
      helpOutline,
      eyeOutline,

      mainListRef,
      itemList,
      formInstance,
      NUMBER_TYPES,
      UNIT_TYPES,
      CUSTOM_TYPES,
      CATEGORY_TYPES,
      UPLOAD_TYPES,
      CAMERA_TYPES,
      PRESET_TYPES,
      categorySelect,
      currentModifiedItem,
      currentModifiedItemAnalyses,
      selectKeyComparisonFunction,
      reorder,
      addItem,
      replaceItem,
      closeSlidingItems,
      addItemDialogOpen,
      editItemDialogOpen,
      openCopyConfirmation,
      openDeleteConfirmation,
      openAddItemDialog,
      openEditItemDialog,
      getAvailableAnalysesForType,
      checkCategorySelectValidity,
      finishItemModification,
      closeDialogs,
      reorderAvailableValues,
      reorderIndexValues,
      openExportConfirmation,
      downloadElement,
      uploadElement,
      readItemListFile,
      openResetConfirmation,
      filterAutocompleteArray,
      showReportPreview
    };
  }
}
</script>

<style>
@media only screen and (min-width: 768px) and (min-height: 600px) {
  .modify-item-dialog {
    /* --width: 100%; */
    --height: 90%;
  }

  .report-preview-dialog {
    /* --width: 100%; */
    --height: 90%;
    --min-width: 700px;
  }
}

.delete-confirmation-okay {
  color: var(--ion-color-danger)!important;
}

.copy-confirmation-okay, .export-confirmation-okay {
  color: var(--ion-color-success-shade)!important;
}

#copy-new-name-input {
  animation: none;
  animation-play-state: paused;
}

#copy-new-name-input.flash-input-short {
  animation: blink-danger-short 0.5s ease-in-out 2;
  animation-play-state: running;
}

@keyframes blink-danger-short {
  50% {
    color: var(--ion-color-danger);
  }
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.modal-confirmation-buttons > * {
  margin-right: 15px;
}

.main-list {
  padding: 0px;
}

.item-action-buttons > :not(:last-child) {
  margin-right: 15px;
}

ion-item {
 display: flex;
 flex-flow: row;
 align-items: center;
 justify-content: center;
}

.modify-item-dialog ion-item ion-label {
  white-space: normal!important;
  flex-grow: 1!important;
  max-width: unset!important;
}

.item-text-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

ion-reorder {
  margin-right: 20px;
}

.category-reorder {
  margin-right: 5px;
}

.sub_category-reorder {
  margin-left: 15px;
  margin-right: 15px;
}

.item-reorder {
  margin-left: 25px;
  margin-right: 25px;
}

ion-checkbox.md {
  margin-left: 8px;
}

.label-fixed {
  width: 220px;
  min-width: 220px;
}

.inner-list {
  width: 100%;
}

ion-list-header.ios {
  margin-bottom: 10px;
}

ion-list-header[disabled=true] {
  opacity: 0.3;
  pointer-events: none;
}

ion-label ion-icon, ion-list-header ion-icon {
  vertical-align: text-bottom;
  margin-left: 3px;
  margin-right: 3px;
}

ion-list-header {
  font-size: 16px;
}

.value-list {
  color: var(--ion-color-secondary-text);
}

.value-list:not(:last-child)::after {
  content: ", ";
  color: var(--ion-color-secondary-text);
}

.text-icon {
  color: var(--ion-color-secondary-text);
  font-size: 0.9em;
  font-style: italic;
}

.header-small-info {
  font-size: 0.7em;
}

.inset-sub-item {
  margin-left: 20px;
}

form {
  height: 100%;
}

.flash-input {
  animation: blink-color 0.5s ease-in-out 3;
  color: var(--ion-color-danger);
}

.flash-input::part(placeholder) {
  opacity: 1;
}

@keyframes blink-color {
  50% {
    color: var(--color);
  }
}

.invisible-input {
  visibility: hidden;
  display: none;
}

.item-with-autocomplete {
  overflow: visible;
  z-index: 100;
}

.dialog-list {
  padding-bottom: 0px;
}

.autocomplete {
  display: none;
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  width: 50%;
  padding-bottom: 15px;
  padding-right: 15px;
}

.autocomplete .list {
  border: 1px solid var(--ion-color-medium);
  box-shadow: 3px 3px 10px var(--ion-color-medium-light);
  overflow-y: auto;
  max-height: 200px;
}

.autocomplete .list ion-list {
  padding: 0px;
}

.item-with-autocomplete.item-has-focus .autocomplete {
  display: block;
}

.logo {
  color: var(--ion-color-secondary-text);
  font-size: 0.7em;
}
</style>
